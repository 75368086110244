import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Moment from "moment"

import "./header.scss"

export default () => {
  const [time, setTime] = useState()
  // Initialize The Doomsday Clock
  const [minutesToMidnight, setMinutesToMidnight] = useState("")
  const [intervalId, setIntervalId] = useState()

  const updateTime = () => {
    setTime(Moment().format("h:mm A"))

    const timeLeft = Moment.duration(Moment().endOf("day") - Moment())
    const minutes = Math.ceil(timeLeft.as("minutes"))

    setMinutesToMidnight(minutes)
  }

  useEffect(() => {
    updateTime()

    setIntervalId(setInterval(updateTime, 1000))

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <div className="header">
      <Link to="/" className="header__logo">
        1440
      </Link>
      <div className="header__application">
        <div className="header__placeholder"></div>
        <div className="header__counter">
          {minutesToMidnight}

          <div className="tooltip">
            Minutes you have left till midnight
            <span className="tooltip__triangle"></span>
          </div>
        </div>
        <div className="header__placeholder header__placeholder--short"></div>
        <div className="header__clock">{time}</div>
      </div>
    </div>
  )
}
